import { useState, useRef, useEffect } from "react";
import splashes from "./splashes";

const storage = window.localStorage;
// Saving loading properties 
export const save = (componentName, field, value) => {
	console.log("Saving", componentName, field, value);
	storage.setItem(componentName + "/" + field, value);
}
export const load = (componentName, field) => {
	console.log("Loading", componentName, field);
	return(storage.getItem(componentName + "/" + field));
}
export const loadOr = (componentName, field, orElse) => {
	const r = load(componentName, field) 
	return(r ? r : orElse);
}
export const toBool = (b) => b === 'true'

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const randomSplash = () => {
  const artists = Object.keys(splashes);
  const amount = artists.length;
  const artistIndex = getRandomIntInclusive(0, amount - 1);
  const artistName = artists[artistIndex];

  const amountBd = splashes[artistName].length;
  const bdIdex = getRandomIntInclusive(0, amountBd - 1);

  const filename = splashes[artistName][bdIdex];
  return { folder: artistName, filename: filename };
};

export const time = () => {
  const date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}


