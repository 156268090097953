import { useState, useRef, useEffect } from "react";
import { useTweaks, makeButton } from "use-tweaks";

import "./App.css";
import {load, loadOr, save, toBool, randomSplash, time, useWindowSize} from "./Utility";

const ReactGA = require("react-ga");

ReactGA.initialize("UA-102501725-3");
ReactGA.set({ page: window.location.pathname + window.location.search });
ReactGA.pageview(window.location.pathname + window.location.search);
const fire = (eventName,f) => {
	ReactGA.event({
		category: 'behavior',
		action: eventName
	});
	console.log("fired",eventName);
	f();
}

// --- components start here
const Clock = () => {
  const ref = useRef(null);
  const tweaks = useTweaks("clock", {
    size: { value: parseFloat(loadOr("clock", "size", "5")), min: 1, max: 10 },
    shadow: toBool(loadOr("clock","shadow","true")),
    title: toBool(loadOr("clock","title", "true")),
    opacity: { value: parseFloat(loadOr("clock","opacity","1")), min: 0, max: 1 },
    color: loadOr("clock", "color", "#fff")
  });
  const [showBanner, setShowBanner] = useState(tweaks.title);
  const style = {
    fontWeight: "800",
    color: tweaks.color,
    opacity: tweaks.opacity,
    fontSize: tweaks.size + "em",
    textShadow: tweaks.shadow ? "0px 0px 2px #000" : "0px 0px 0px #000",
  };
  useEffect(() => {
    setTimeout(() => {
      setShowBanner(false);
    }, 1000);
  });
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("clock", k, tweaks[k])
		})
	}, [tweaks])
  return (
    <div id="centering">
      <div id="clock" ref={ref} style={style}>
        {showBanner ? "8bitdash" : time()}
      </div>
    </div>
  );
};

// --- bar component

const Bar = ({ splash, fontName }) => {
  const barStyle = {
    position: "absolute",
    textAlign: "center",
    fontWeight: "400",
		opacity: 0.5,
    fontSize: "1em",
    bottom: "0px",
    height: "2.0em",
    width: "100%",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  };
  return (
    <div style={barStyle}>
      <div className="padded-left">
        {splash ? splash.folder : ""} / {fontName}
      </div>
      <div className="padded-right">
				<ReactGA.OutboundLink
					eventLabel="discord"
					to="https://discord.gg/WStut4A4ma"
				>Discord</ReactGA.OutboundLink> 
      </div>
    </div>
  );
};

const TopBar = ({ showSettings }) => {
  const barStyle = {
    position: "absolute",
    textAlign: "center",
    fontWeight: "400",
    fontSize: "1.0em",
    top: "0.5em",
		opacity: 0.5,
    zIndex: "9",
    height: "1.0vw",
    width: "100%",
    display: "flex",
    flexDirection: "columns",
    justifyContent: "space-between",
  };
  return (
    <div style={barStyle}>
      <div></div>
      <div className="padded-right">
        <a onClick={() => fire("show-settings",showSettings)} href="#">
          Tweaks
        </a>
      </div>
    </div>
  );
};

// --- app root component

function App() {
  const ref = useRef(null);
  const size = useWindowSize();
  const tweaks = useTweaks("app", {
    clock: toBool(loadOr("app", "clock","true")),
    smooth: toBool(loadOr("app", "smooth", "false")),
    font_i: { value: parseFloat(loadOr("app", "font_i", "0")), min: 0, max: 4 },
    ...makeButton("Change splash", () => setSplash(randomSplash())),
    ...makeButton("Hide tweaks", () => setShowTweaks(false)),
  });

  const [splash, setSplash] = useState(null);
  const [font, setFont] = useState("minecraftfont");
  const [showTweaks, setShowTweaks] = useState(toBool(loadOr("app","tweaks","true")));

  const fonts = ["minecraftfont", "print21", "Inter"];

  const backdropUrl = splash
    ? "splash/" + splash.folder + "/" + splash.filename
    : "";
  const appStyle = {
    fontFamily: font,
    color: "white",
    height: size.height + "px",
    width: size.width + "px",
    background: "url(" + backdropUrl + ") no-repeat fixed",
    imageRendering: tweaks.smooth ? "auto" : "crisp-edges",
  };

  useEffect(() => {
    setSplash(randomSplash());
  }, []);
  useEffect(() => {
    ref.current.style.backgroundSize = "cover";
  }, [appStyle]);
  useEffect(() => {
    setFont(fonts[Math.floor(tweaks.font_i)]);
  }, [tweaks.font_i]);
  useEffect(() => {
    const el = document.getElementsByClassName("tp-dfwv")[0];
    el.hidden = !showTweaks;
		save("app","tweaks", showTweaks)
  }, [showTweaks]);
	useEffect(() => {
		Object.keys(tweaks).forEach(k => {
			save("app", k, tweaks[k])
		})
	}, [tweaks])

  // if tweaks is hidden, then display the top bar
  // have a newsletter that people can sign up whenever theres new art available
  return (
    <div ref={ref} style={appStyle} className="App">
      {!showTweaks ? <TopBar showSettings={() => setShowTweaks(true)} /> : null}
      {tweaks.clock ? <Clock /> : null}
      <Bar splash={splash} fontName={font} />
    </div>
  );
}

export default App;
